import { Routes, Route, Outlet } from "react-router-dom";
import Header from '../header/header';
import Footer from '../footer/footer';

import './App.css';

import { ContactUsPage, HomePage, ProgramsPage, ProgramsDetailPage, NotFoundPage, ExamPrepsPage, ExamPrepsDetailPage } from '../pages/';

function App() {
  return (
    <Routes>
      <Route path="/" exact element={<HomePage />}>
      </Route>
      <Route path="/" element={<Layout />}>
        <Route path="programs" element={<ProgramsPage />} />
        <Route path="program/:tag" element={<ProgramsDetailPage />} />
        <Route path="exampreps" element={<ExamPrepsPage />} />
        <Route path="examprep/:tag" element={<ExamPrepsDetailPage />} />
        <Route path="contact-us" element={<ContactUsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>
    </>
  );
}

export default App;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';

import programs from '../../../data/programs.json';

import timeIcon from '../../../assets/images/time-icon.svg';
import crdPlay from '../../../assets/images/crd-play.svg';
import language from '../../../assets/images/language.svg';
import monitor from '../../../assets/images/monitor-icon.svg';
import certificate from '../../../assets/images/certificate.svg';

import './programs-detail-page.css';

function ProgramsDetailPage() {
    const { tag } = useParams();
    const [program, setProgram] = useState();

    useEffect(() => {
        setProgram(programs.find(x => x.tag === tag));
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }, [tag]);

    return (
        <main class="main-page-content main-top-gap">
            <section class="course-detail-v2-main-sec position-relative pb-8">
            <div class="course-detail-item course-detail-banner pt-8 bg-primary">
                <div class="container"> 
                <h2 class="sec-heading">{ program?.name }</h2>
                <div class="d-flex justify-content-between flex-wrap flex-lg-nowrap"> 
                    <p class="sec-brief mt-4 mb-4 me-lg-5">{ program?.headline }</p>
                    <div class="course-related-info-list d-flex flex-wrap align-items-center">
                    <div class="corse-info-item d-flex align-items-center me-3 my-2"><img class="me-1" src={timeIcon} alt="Description"/><span class="corse-info-txt">{ program?.duration } weeks</span></div>
                    <div class="corse-info-item d-flex align-items-center me-3 my-2"><img class="me-1" src={crdPlay} alt="Description"/><span class="corse-info-txt">{ program?.modules?.reduce((acc, val) => acc + val?.lessons.length, 0) } lessons</span></div>
                    </div>
                </div>
                </div>
            </div>
            <div class="container"> 
                <div class="course-detail-grid position-relative">
                <div class="course-detail-big-img">
                    <picture> 
                    <source srcset={"/images/programs/"+program?.coverImage+".webp"} type="image/png"/><img class="cover w-100" src={"/images/programs/"+program?.coverImage+".jpg"} alt="program cover"/>
                    </picture>
                </div>
                <div class="course-detail-item course-detail-crd p-3">
                    <div class="course-detail-crd-content mt-3"> 
                    <div class="course-detail-crd-head d-lg-block d-flex align-items-center justify-content-center flex-wrap justify-content-sm-between">
                        <div class="course-price d-flex align-items-center justify-content-center mb-3 mb-sm-0 mb-lg-3">
                        <h2 class="sec-heading m-0">${ program?.price?.toLocaleString() }</h2>
                        </div>
                        <a class="btn btn-primary my-4 course-detail-crd-btn" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Register</a>
                    </div>
                    <div class="course-include">
                        <h3 class="card-title mb-3">This course includes</h3>
                        <ul class="course-include-list d-sm-flex flex-wrap d-lg-block">
                            <li class="course-include-item mb-3 me-3 d-flex align-items-center"> <img class="me-3" src={language} alt="Description"/><span class="card-text">Language - English </span></li>
                            <li class="course-include-item mb-3 me-3 d-flex align-items-center"> <img class="me-3" src={monitor} alt="Description"/><span class="card-text">Online or In-Person</span></li>
                            { program?.hasCertificate &&
                                <li class="course-include-item d-flex align-items-center"> <img class="me-3" src={certificate} alt="Description"/><span class="card-text">Certificate of Completion </span></li>
                            }
                        </ul>
                    </div>
                    <div class="tranining-people border-top border-bottom py-3 my-3">
                        <h4 class="card-subtitle mb-3">Training 5 or more people?</h4>
                        <p class="card-text"><a class="text-focus" href="https://forms.gle/FbEdGKEH1mSyLUay6" target="_blank" rel="noreferrer">Contact us</a> for more information. </p>
                    </div>
                    <div class="share-social">
                        <h4 class="card-subtitle mb-3">Share this course</h4>
                        <ul class="social-icon-list mt-3 d-flex align-items-center justify-content-between">
                        <li class="social-icon-item">
                            <a class="d-block" href="https://www.instagram.com/">
                            <svg class="insta-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <lineargradient id="myGradient" gradienttransform="rotate(90)">
                                <stop offset="5%" stop-color="#DD2A7B"></stop>
                                <stop offset="95%" stop-color="#8134AF"></stop>
                                </lineargradient>
                                <path d="M150.715,137.679a3.154,3.154,0,0,0-1.807-1.807,5.269,5.269,0,0,0-1.768-.328c-1-.046-1.306-.056-3.848-.056s-2.844.01-3.848.055a5.273,5.273,0,0,0-1.768.328,3.155,3.155,0,0,0-1.808,1.807,5.271,5.271,0,0,0-.328,1.768c-.046,1-.056,1.305-.056,3.848s.01,2.844.056,3.848a5.27,5.27,0,0,0,.328,1.768,3.154,3.154,0,0,0,1.807,1.807,5.262,5.262,0,0,0,1.768.328c1,.046,1.305.055,3.848.055s2.844-.01,3.848-.055a5.262,5.262,0,0,0,1.768-.328,3.154,3.154,0,0,0,1.807-1.807,5.274,5.274,0,0,0,.328-1.768c.046-1,.055-1.306.055-3.848s-.01-2.844-.055-3.848A5.262,5.262,0,0,0,150.715,137.679Zm-7.424,10.506a4.89,4.89,0,1,1,4.89-4.89A4.89,4.89,0,0,1,143.292,148.185Zm5.083-8.831a1.143,1.143,0,1,1,1.143-1.143A1.143,1.143,0,0,1,148.375,139.355Zm0,0" transform="translate(-127.292 -127.295)"></path>
                                <path d="M213.349,210.174A3.174,3.174,0,1,1,210.174,207,3.174,3.174,0,0,1,213.349,210.174Zm0,0" transform="translate(-194.174 -194.174)"></path>
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H29.692A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0Zm-4.56,19.788a6.741,6.741,0,0,1-.427,2.23,4.7,4.7,0,0,1-2.687,2.687,6.745,6.745,0,0,1-2.23.427c-.98.045-1.293.055-3.788.055s-2.808-.011-3.788-.055a6.745,6.745,0,0,1-2.23-.427A4.7,4.7,0,0,1,7.3,22.018a6.739,6.739,0,0,1-.427-2.23c-.045-.98-.056-1.293-.056-3.788s.01-2.808.055-3.788a6.741,6.741,0,0,1,.427-2.23A4.7,4.7,0,0,1,9.982,7.295a6.747,6.747,0,0,1,2.23-.427c.98-.045,1.293-.055,3.788-.055s2.808.011,3.788.056a6.748,6.748,0,0,1,2.23.427,4.7,4.7,0,0,1,2.687,2.687,6.74,6.74,0,0,1,.427,2.23c.045.98.055,1.293.055,3.788S25.177,18.808,25.132,19.788Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://www.facebook.com/">
                            <svg class="fb-icon" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H17.094V19.625H12.938V14.781h4.156V11.217c0-4.134,2.524-6.384,6.211-6.384a34.181,34.181,0,0,1,3.726.19V9.344H24.489c-2.006,0-2.395.953-2.395,2.353v3.085h4.8l-.625,4.844H22.094V32h7.6A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://www.whatsapp.com/">
                            <svg class="whatsapp" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <path d="M142.077,124.469a7.97,7.97,0,0,0-8.006,7.912,7.83,7.83,0,0,0,1.224,4.211l.19.3-.809,2.92,3.029-.786.292.171a8.058,8.058,0,0,0,4.073,1.1h0a7.97,7.97,0,0,0,8-7.913,7.821,7.821,0,0,0-2.342-5.6A8,8,0,0,0,142.077,124.469Zm4.707,11.314a2.479,2.479,0,0,1-1.624,1.131,3.337,3.337,0,0,1-1.515-.094,13.988,13.988,0,0,1-1.372-.5,10.678,10.678,0,0,1-4.11-3.592,4.6,4.6,0,0,1-.982-2.461,2.655,2.655,0,0,1,.842-1.984.889.889,0,0,1,.642-.3c.16,0,.321,0,.461.008s.346-.056.541.408.682,1.647.742,1.766a.435.435,0,0,1,.02.417,8.607,8.607,0,0,1-.6.814c-.106.131-.246.247-.106.486a7.186,7.186,0,0,0,1.338,1.648A6.593,6.593,0,0,0,143,134.711c.24.119.381.1.521-.059s.6-.695.762-.933.321-.2.541-.119,1.4.655,1.644.774.4.179.461.278A1.964,1.964,0,0,1,146.784,135.783Zm0,0" transform="translate(-125.736 -116.647)"></path>
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H29.692A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0ZM16.339,25.316h0a9.572,9.572,0,0,1-4.576-1.165L6.682,25.482l1.359-4.961a9.577,9.577,0,1,1,8.3,4.795Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://twitter.com/">
                            <svg class="twitter" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H29.692A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0ZM23.305,12.475q.011.236.01.475A10.378,10.378,0,0,1,12.867,23.4h0a10.4,10.4,0,0,1-5.629-1.65,7.47,7.47,0,0,0,.876.051,7.369,7.369,0,0,0,4.561-1.572,3.677,3.677,0,0,1-3.431-2.551,3.66,3.66,0,0,0,1.659-.063,3.673,3.673,0,0,1-2.946-3.6c0-.017,0-.031,0-.047a3.647,3.647,0,0,0,1.663.459,3.676,3.676,0,0,1-1.137-4.9,10.427,10.427,0,0,0,7.57,3.837,3.674,3.674,0,0,1,6.258-3.349,7.366,7.366,0,0,0,2.332-.891,3.687,3.687,0,0,1-1.615,2.031,7.324,7.324,0,0,0,2.109-.578A7.463,7.463,0,0,1,23.305,12.475Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://in.indeed.com/">
                            <svg class="indeed" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H29.692A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0ZM11.351,24.188h-3.9V12.464h3.9ZM9.4,10.863H9.377a2.031,2.031,0,1,1,.051-4.051A2.032,2.032,0,1,1,9.4,10.863Zm16,13.324h-3.9V17.916c0-1.576-.564-2.651-1.974-2.651a2.133,2.133,0,0,0-2,1.425,2.668,2.668,0,0,0-.128.951v6.547h-3.9s.051-10.624,0-11.723h3.9v1.66a3.869,3.869,0,0,1,3.512-1.935c2.564,0,4.486,1.676,4.486,5.276Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://www.youtube.com/">
                            <svg class="youtube" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <path d="M224.113,214.078l5.243-3.02-5.243-3.02Zm0,0" transform="translate(-210.126 -195.059)"></path>
                                <path d="M29.692,0H2.308A2.308,2.308,0,0,0,0,2.308V29.692A2.308,2.308,0,0,0,2.308,32H29.692A2.308,2.308,0,0,0,32,29.692V2.308A2.308,2.308,0,0,0,29.692,0ZM26,16.016a26.408,26.408,0,0,1-.412,4.81,2.505,2.505,0,0,1-1.762,1.762C22.259,23,16,23,16,23s-6.242,0-7.823-.428a2.506,2.506,0,0,1-1.762-1.762A26.292,26.292,0,0,1,6,16a26.39,26.39,0,0,1,.412-4.809A2.556,2.556,0,0,1,8.177,9.412C9.741,9,16,9,16,9s6.259,0,7.823.428a2.506,2.506,0,0,1,1.762,1.762A25.06,25.06,0,0,1,26,16.016Zm0,0"></path>
                            </svg></a>
                        </li>
                        <li class="social-icon-item">
                            <a class="d-block" href="https://www.reddit.com/">
                            <svg class="reddit" xmlns="http://www.w3.org/2000/svg" width="31.997" height="31.997" viewbox="0 0 31.997 31.997">
                                <path d="M173.982,257.667a1.667,1.667,0,1,0-1.667,1.667A1.67,1.67,0,0,0,173.982,257.667Zm0,0" transform="translate(-159.984 -240.001)"></path>
                                <path d="M198.114,341.439a6.244,6.244,0,0,1-6.693,0,.432.432,0,0,0-.611.611c1.136,1.136,3.318,1.225,3.957,1.225s2.819-.09,3.958-1.225a.437.437,0,0,0,0-.611A.431.431,0,0,0,198.114,341.439Zm0,0" transform="translate(-178.765 -319.981)"></path>
                                <path d="M289.667,256a1.667,1.667,0,1,0,1.667,1.667A1.67,1.67,0,0,0,289.667,256Zm0,0" transform="translate(-270.001 -240.001)"></path>
                                <path d="M29.69,0H2.308A2.308,2.308,0,0,0,0,2.308V29.69A2.308,2.308,0,0,0,2.308,32H29.69A2.308,2.308,0,0,0,32,29.69V2.308A2.308,2.308,0,0,0,29.69,0ZM25.281,18.133a4.642,4.642,0,0,1,.054.7c0,3.59-4.179,6.5-9.334,6.5s-9.334-2.909-9.334-6.5a4.694,4.694,0,0,1,.054-.707,2.332,2.332,0,1,1,2.566-3.811,11.44,11.44,0,0,1,6.352-1.978c0-.032,1.165-5.58,1.165-5.58a.411.411,0,0,1,.179-.262.416.416,0,0,1,.314-.058l3.878.825a1.667,1.667,0,1,1-.17.816L17.534,7.34l-1.062,5a11.414,11.414,0,0,1,6.239,1.981A2.331,2.331,0,0,1,26.663,16,2.343,2.343,0,0,1,25.281,18.133Zm0,0"></path>
                            </svg></a>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
                <div class="course-detail-item course-detail-accodians mt-8 mt-lg-5">
                    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="me-4 my-2" role="presentation">
                        <button class="btn btn-secondary active" id="pills-overview-tab" data-bs-toggle="pill" data-bs-target="#pills-overview" type="button" role="tab" aria-controls="pills-overview" aria-selected="true">Overview</button>
                    </li>
                    <li class="me-4 my-2" role="presentation">
                        <button class="btn btn-secondary" id="pills-curriculum-tab" data-bs-toggle="pill" data-bs-target="#pills-curriculum" type="button" role="tab" aria-controls="pills-curriculum" aria-selected="false">Curriculum</button>
                    </li>
                    </ul>
                    <div class="tab-content mt-5" id="pills-tabContent">
                    <div class="tab-pane fade show active" id="pills-overview" role="tabpanel" aria-labelledby="pills-overview-tab"> 
                        <ul class="overview-list">
                            { program?.overviews?.map((overview) => (
                                <li class="overview-item my-2"> 
                                    <h3 class="card-title">{ overview?.title }</h3>
                                    { overview?.descriptions?.map((description) => (
                                            <p class="card-text">{ parse(description) }</p>
                                        )) }
                                </li>
                            )) }
                        </ul>
                    </div>
                    <div class="tab-pane fade" id="pills-curriculum" role="tabpanel" aria-labelledby="pills-curriculum-tab"> 
                        <div class="accordion course-detail-accordion" id="accordionCurriculum">
                            { program?.modules?.map((module,moduleIndex) => (
                                <div class="accordion-item">
                                    <div class="accordion-header" id={"heading"+moduleIndex}>
                                    <button class={ moduleIndex === 0 ? "accordion-button justify-content-between" : "accordion-button collapsed justify-content-between" } type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+moduleIndex} aria-expanded="true" aria-controls={"collapse"+moduleIndex}><span class="card-subtitle">{ module?.name }</span><span class="d-none d-sm-block card-text">{ module?.lessons?.length > 1 ? `${module?.lessons?.length} lessons` : `${module?.lessons?.length} lesson` }</span></button>
                                    </div>
                                    <div class={ moduleIndex === 0 ? "accordion-collapse collapse show" : "accordion-collapse collapse" } id={"collapse"+moduleIndex} aria-labelledby={"heading"+moduleIndex} data-bs-parent="#accordionCurriculum">
                                    <div class="accordion-body">
                                        { module?.lessons?.map((lesson,lessonIndex) => (
                                            <div class={ (module?.lessons.length === lessonIndex + 1)  ? "lecture-info d-flex align-items-center justify-content-between" : "lecture-info d-flex align-items-center justify-content-between border-bottom pb-1 mb-1" }><span class="card-text lecture-name">{ lesson?.name }</span><span class="card-text lecture-timing"></span></div>
                                        )) }
                                    </div>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        </main>
    );
  }
  
  export default ProgramsDetailPage;
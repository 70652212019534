import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from "../../header/header";
import Footer from "../../footer/footer";

import programs from '../../../data/programs.json';
import exampreps from '../../../data/exampreps.json';

import courses from '../../../assets/images/courses.svg';
import expert from '../../../assets/images/expert.svg';
import timeIcon from '../../../assets/images/time-icon.svg';
import crdPlay from '../../../assets/images/crd-play.svg';
import circle from '../../../assets/images/circle.svg';

import circle1 from '../../../assets/images/circle1.png';
import circle2 from '../../../assets/images/circle2.png';
import circle3 from '../../../assets/images/circle3.png';
import circle5 from '../../../assets/images/circle5.png';
import circle6 from '../../../assets/images/circle6.png';
import circle7 from '../../../assets/images/circle7.png';
import girlWithLaptop1 from '../../../assets/images/girl-with-laptop.webp';
import girlWithLaptop2 from '../../../assets/images/girl-with-laptop2.webp';
import girlWithLaptop3 from '../../../assets/images/girl-with-laptop3.webp';

function HomePage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }, []);

    return (
        <>
            <Header isLight="true"/>
            <main class="main-page-content">
            <section class="hero-banner-v3 position-relative bg-dark d-flex align-items-center">
                <div class="container"> 
                <div class="banner-content text-center">
                    <h1 class="banner-heading text-white">To expand your career, get in touch with us.</h1>
                    <p class="banner-brief text-white mt-3 my-md-4">Here you can select the best program for your career from a wide range of courses.</p><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Get Started Now</a>
                </div>
                </div>
            </section>
            <section class="skilify-info-sec">
                <div class="container"> 
                <ul class="d-flex flex-wrap align-items-center skilify-info-points bg-white position-relative p-2">
                    <li class="skilify-info-point d-flex align-items-center p-2 p-md-4">
                    <div class="skilify-info-point-img me-3"><img src={courses} alt="Courses"/></div>
                    <div class="skilify-info-point-txt"> 
                        <h3 class="card-title m-0">Online or In-Person</h3>
                    </div>
                    </li>
                    <li class="skilify-info-point info-point-primary d-flex align-items-center p-2 p-md-4">
                    <div class="skilify-info-point-img me-3"><img src={expert} alt="Description"/></div>
                    <div class="skilify-info-point-txt"> 
                        <h3 class="card-title m-0">Expert Instruction</h3>
                    </div>
                    </li>
                    <li class="skilify-info-point d-flex align-items-center p-2 p-md-4">
                    <div class="skilify-info-point-img me-3"><img src={courses} alt="Description"/></div>
                    <div class="skilify-info-point-txt"> 
                        <h3 class="card-title m-0">Job Search Guidance</h3>
                    </div>
                    </li>
                </ul>
                </div>
            </section>
            <section class="py-4 bg-primary">
                <div class="container"> 
                    <div class="section-container"> 
                        <div class="row apply-crd-list">
                            <div class="col-12">
                                <div class="row apply-crd m-0">
                                <div class="apply-crd-content col-lg-10 mt-4 mt-lg-0">
                                    <h3 class="card-title mb-2">Next webinar date: February 28, 2023 7pm EST/Toronto</h3>
                                    <a class="btn btn-primary"  href="https://forms.gle/BkhPYB3TvFK8micY7" target="_blank" rel="noreferrer">Register Webinar</a>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="multi-course-crd-sec py-4 bg-white">
                <div class="container"> 
                        <div class="sec-header mb-5 d-md-flex align-items-center justify-content-between text-center text-md-start">
                        <h2 class="m-0 sec-heading">Get choice of your certificate program</h2>
                        <div class="sec-header-btn mt-4 m-md-0"><Link class="btn btn-primary" to="/programs">View all</Link></div>
                        </div>
                <div class="sec-content"> 
                    <div class="row multi-course-crd-list">
                        { programs?.slice(0, 3)?.map((program) => (
                                <div class="col-md-6 col-lg-4">
                                    <div class="course-card">
                                        <div class="crd-img-wrap position-relative"> 
                                        <Link to={"/program/"+program?.tag}>
                                            <picture> 
                                            <source srcset={"/images/programs/"+program?.listingImage+".webp"} type="image/webp"/><img class="crd-img" src={"/images/programs/"+program?.listingImage+".jpg"}  alt="Program"/>
                                            </picture></Link>
                                        </div>
                                        <div class="crd-content">
                                        <div class="crd-info py-2 d-flex align-items-center justify-content-between">
                                            <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={timeIcon} alt="Duration"/><span class="crd-info-txt">{ program?.duration } weeks</span></div>
                                            <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={crdPlay} alt="Lessons"/><span class="crd-info-txt">{ program?.modules?.reduce((acc, val) => acc + val?.lessons.length, 0) } lessons</span></div>
                                        </div><Link to={"/program/"+program?.tag}>
                                            <h3 class="card-title my-3">{ program?.name }</h3></Link>
                                        </div>
                                    </div>
                                </div>
                            )) }
                    </div>
                </div>
                </div>
            </section>
            <section class="multi-course-crd-sec py-4 bg-white">
                <div class="container"> 
                        <div class="sec-header mb-5 d-md-flex align-items-center justify-content-between text-center text-md-start">
                        <h2 class="m-0 sec-heading">Get choice of your exam preparation</h2>
                        <div class="sec-header-btn mt-4 m-md-0"><Link class="btn btn-primary" to="/exampreps">View all</Link></div>
                        </div>
                <div class="sec-content"> 
                    <div class="row multi-course-crd-list"> 
                        { exampreps?.slice(0, 3)?.map((examprep) => (
                                <div class="col-md-6 col-lg-4">
                                    <div class="course-card">
                                        <div class="crd-img-wrap position-relative"> 
                                        <Link to={"/examprep/"+examprep?.tag}>
                                            <picture> 
                                            <source srcset={"/images/programs/"+examprep?.listingImage+".webp"} type="image/webp"/><img class="crd-img" src={"/images/programs/"+examprep?.listingImage+".jpg"}  alt="Program"/>
                                            </picture></Link>
                                        </div>
                                        <div class="crd-content">
                                        <div class="crd-info py-2 d-flex align-items-center justify-content-between">
                                            <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={timeIcon} alt="Duration"/><span class="crd-info-txt">{ examprep?.duration } weeks</span></div>
                                            <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={crdPlay} alt="Lessons"/><span class="crd-info-txt">{ examprep?.modules?.reduce((acc, val) => acc + val?.lessons.length, 0) } lessons</span></div>
                                        </div><Link to={"/examprep/"+examprep?.tag}>
                                            <h3 class="card-title my-3">{ examprep?.name }</h3></Link>
                                        </div>
                                    </div>
                                </div>
                            )) }
                    </div>
                </div>
                </div>
            </section>
            <section class="py-8 learn-steps-sec-v2">
                <div class="container"> 
                        <div class="sec-header mb-5 d-md-flex align-items-center justify-content-between text-center text-md-start">
                        <h2 class="m-0 sec-heading">What makes us UNIQUE?</h2>
                        <div class="sec-header-btn mt-4 m-md-0"><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Start now</a></div>
                        </div>
                <div class="row learn-steps-content align-items-center"> 
                    <div class="col-md-6 learn-steps-list"> 
                    <div class="d-flex learn-step position-relative">
                        <div class="step-left-side">
                        <h3 class="card-title">01.</h3><span class="learn-step-line d-block"> </span>
                        </div>
                        <div class="step-right-side ps-3">
                        <h3 class="card-title mb-3">Learn from Industry Experts</h3>
                        <p class="card-text">You will learn from our experts who have many years of experience in education and in their respective fields. </p>
                        </div>
                    </div>
                    <div class="d-flex learn-step py-3">
                        <div class="step-left-side position-relative">
                        <h3 class="card-title">02.</h3><span class="learn-step-line d-block"> </span>
                        </div>
                        <div class="step-right-side ps-3">
                        <h3 class="card-title mb-3">Hands on Experience and Capstone Project</h3>
                        <p class="card-text">With capstone project, you will have a real-life working experience  and enhance your technical knowledge.</p>
                        </div>
                    </div>
                    <div class="d-flex learn-step">
                        <div class="step-left-side position-relative">
                        <h3 class="card-title">03.</h3><span class="learn-step-line d-block"></span>
                        </div>
                        <div class="step-right-side ps-3">
                        <h3 class="card-title mb-3">Earn a Certificate of Completion</h3>
                        <p class="card-text">Once you complete your course, you will earn a certicate of completion which can be used as proof of knowledge. </p>
                        </div>
                    </div>
                    <div class="d-flex learn-step py-3">
                        <div class="step-left-side position-relative">
                        <h3 class="card-title">04.</h3><span class="learn-step-line d-block"></span>
                        </div>
                        <div class="step-right-side ps-3">
                        <h3 class="card-title mb-3">Guidance for Job Interviews</h3>
                        <p class="card-text">You will be directed for improving technical and behavioural interview skills.</p>
                        </div>
                    </div>
                    </div>
                    <div class="col-md-6 learn-steps-images d-none d-md-block position-relative text-center"> 
                    <div class="d-md-flex align-items-end justify-content-center mb-4">
                        <div class="learn-step-img-wrap bg-white me-3 position-relative"> 
                        <picture class="text-center">
                            <source srcset={girlWithLaptop2} type="image/webp"/><img class="learn-step-img1" src="images/img/girl-with-laptop2.png" alt="Description"/>
                        </picture><span class="bg-secondary mt-2"> </span><span class="bg-secondary mt-2"></span><span class="bg-secondary mt-2"></span>
                        </div>
                        <div class="learn-step-img-wrap bg-white position-relative ms-3"> 
                        <picture>
                            <source srcset={girlWithLaptop1} type="image/webp"/><img class="learn-step-img2 bg-white" src="images/img/girl-with-laptop.png" alt="Description"/>
                        </picture>
                        </div>
                    </div>
                    <div class="learn-step-img-wrap text-center bg-white position-relative d-inline-block"> 
                        <picture>
                        <source srcset={girlWithLaptop3} type="image/webp"/><img class="learn-step-img3 bg-white" src="images/img/girl-with-laptop3.png" alt="Description"/>
                        </picture>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section class="py-8 bg-primary remote-learning-sec position-relative overflow-hidden">
                <div class="patterns-wrap d-none d-xl-block"><img class="remote-circle2" src={circle2} alt="Description"/></div>
                <div class="container"> 
                <div class="position-relative remote-learning-content-wrap py-8">
                    <div class="patterns-wrap"><img class="remote-circle1 d-none d-sm-block" src={circle1} alt="Description"/><img class="remote-circle3 d-none d-lg-block" src={circle} alt="Description"/><img class="remote-circle4 d-none d-md-block" src={circle3} alt="Description"/></div>
                    <div class="remote-learning-content text-center position-relative z-index-2">
                    <h2 class="sec-heading">Select the best career path that fits for you with the help of our various programs. </h2>
                    <p class="sec-brief mb-5 mt-4">Our programs give you the necessary knowledge to pursue your career in that field, and our experts will guide you properly.</p><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Join Today</a>
                    </div>
                    <div class="patterns-wrap"><img class="remote-circle5 d-none d-lg-block" src={circle5} alt="Description"/><img class="remote-circle6 d-none d-sm-block" src={circle} alt="Description"/><img class="remote-circle7 d-none d-lg-block" src={circle6} alt="Description"/><img class="remote-circle8 d-none d-sm-block" src={circle7} alt="Description"/></div>
                </div>
                </div>
            </section>
            </main>
            <Footer/>
        </>
    );
  }
  
  export default HomePage;
import { useEffect } from 'react';

import bannerWebp from '../../../assets/images/contactus-banner.webp';
import bannerPng from '../../../assets/images/contactus-banner.png';
import contactUs1Webp from '../../../assets/images/contactus1.webp';
import contactUs1Png from '../../../assets/images/contactus1.png';
import contactUs2Webp from '../../../assets/images/contactus2.webp';
import contactUs2Png from '../../../assets/images/contactus2.png';

function ContactUsPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }, []);

    return (
        <main class="main-page-content main-top-gap">
            <section class="pt-8 bg-primary contactus-banner position-relative">
                <div class="container"> 
                <div class="row align-items-center position-relative contactus-banner-row">
                    <div class="col-md-6 pe-md-5">
                    <div class="banner-content text-center text-md-start"> <span class="sub-banner-heading d-block">CONTACT</span>
                        <h1 class="banner-heading">Got a question about using Technodemy?</h1>
                        <p class="banner-brief text-secondary mt-3 my-md-4"></p><a class="btn btn-primary" href="https://forms.gle/FbEdGKEH1mSyLUay6" target="_blank" rel="noreferrer">Contact Us Form</a>
                    </div>
                    </div>
                    <div class="col-md-6 text-center text-md-end">  
                    <div class="contactus-banner-img">
                        <picture>
                        <source srcset={bannerWebp} type="image/webp"/><img src={bannerPng} alt="Description"/>
                        </picture>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <section class="contactus-main-sec pt-8"> 
                <div class="container"> 
                        <div class="sec-header mb-5 d-md-flex align-items-center justify-content-between text-center text-md-start">
                        <h2 class="m-0 sec-heading">Expanding learning opportunities</h2>
                        <div class="sec-header-btn mt-4 m-md-0"><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Join Now</a></div>
                        </div>
                <ul class="contactus-list"> 
                    <li class="contactus-item mb-8 bg-primary-after position-relative py-md-5 px-md-0 px-3 py-3">
                    <div class="row align-items-center ps-md-3">
                        <div class="col-md-6 ps-md-5 order-md-0 order-2 mt-md-0 mt-3"><span class="sec-sub-heading text-secondary">CONTACT</span>
                        <h3 class="sec-heading mb-2">Toronto, Canada</h3>
                        <p class="card-text">Our mission is to remove the barriers for new immigrants in Canada.</p>
                        <div class="contactus-address d-flex align-items-center">
                            <div class="location-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <g transform="translate(-0.201 -0.201)">
                                <path d="M16.2,2C9.509,2,4.067,7.018,4,13.247,4,21.029,14.752,29.691,15.209,30.06a1.612,1.612,0,0,0,1.983,0c.534-.369,11.209-9.032,11.209-16.813C28.334,7.017,22.891,2,16.2,2Zm0,25.064c-2.547-2.258-9.15-8.52-9.15-13.817,0-4.706,4.1-8.52,9.15-8.52s9.15,3.815,9.15,8.52C25.351,18.5,18.747,24.806,16.2,27.064Z" transform="translate(0 0)"></path>
                                <path d="M12,6a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,12,6Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,11Z" transform="translate(4.201 2.965)"></path>
                                </g>
                            </svg>
                            </div>
                            <p class="contactus-address-txt mb-0 ms-2 text-secondary">Toronto, ON, M2N1J5</p>
                        </div>
                        </div>
                        <div class="col-md-6">
                        <picture> 
                            <source srcset={contactUs1Webp} type="image/webp"/><img src={contactUs1Png} alt="Description"/>
                        </picture>
                        </div>
                    </div>
                    </li>
                    <li class="contactus-item contactus-item-reverse mb-8 bg-purple-after position-relative py-md-5 px-md-0 px-3 py-3">
                    <div class="row align-items-center ps-md-3">  
                        <div class="col-md-6">
                        <picture> 
                            <source srcset={contactUs2Webp} type="image/webp"/><img src={contactUs2Png} alt="Description"/>
                        </picture>
                        </div>
                        <div class="col-md-6 pe-md-5 ps-md-3 order-md-0 order-2 mt-md-0 mt-3"><span class="sec-sub-heading text-secondary">CONTACT</span>
                        <h3 class="sec-heading mb-2">Ankara, Turkey</h3>
                        <p class="card-text"></p>
                        <div class="contactus-address d-flex align-items-center">
                            <div class="location-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewbox="0 0 32 32">
                                <g transform="translate(-0.201 -0.201)">
                                <path d="M16.2,2C9.509,2,4.067,7.018,4,13.247,4,21.029,14.752,29.691,15.209,30.06a1.612,1.612,0,0,0,1.983,0c.534-.369,11.209-9.032,11.209-16.813C28.334,7.017,22.891,2,16.2,2Zm0,25.064c-2.547-2.258-9.15-8.52-9.15-13.817,0-4.706,4.1-8.52,9.15-8.52s9.15,3.815,9.15,8.52C25.351,18.5,18.747,24.806,16.2,27.064Z" transform="translate(0 0)"></path>
                                <path d="M12,6a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,12,6Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,11Z" transform="translate(4.201 2.965)"></path>
                                </g>
                            </svg>
                            </div>
                            <p class="contactus-address-txt mb-0 ms-2 text-secondary">Ankara, Turkey, 06530</p>
                        </div>
                        </div>
                    </div>
                    </li>
                </ul>
                </div>
            </section>
            <section class="py-8 two-side-curve-sec">
                <div class="container"> 
                <div class="two-side-curve-content text-center bg-dark">   
                    <h2 class="sec-heading text-white">It's high time to get yourself industry ready!</h2>
                    <p class="sec-brief mt-3 mb-4 text-white">Learn and find the best match for your career with our courses to get a successful path. So join Technodemy now and start a tech career.</p><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Get Started</a>
                </div>
                </div>
            </section>
            </main>
    );
  }
  
  export default ContactUsPage;
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import programs from '../../data/programs.json';
import exampreps from '../../data/exampreps.json';

import logo from '../../assets/images/logo.png';

function Header(props) {
  const [sticky, setSticky] = useState("");

  useEffect(() => {
    let header = document.querySelector(".header");
    let navbarToggler = document.querySelector(".header .navbar-toggler")
    navbarToggler.addEventListener("click" ,()=>{
        if(navbarToggler.classList.contains("collapsed")){
            header.setAttribute("style","")
            document.body.setAttribute("style","overflow:auto;");
        }
        else{
            header.setAttribute("style","background-color: #fff;");
            
            document.body.setAttribute("style","overflow:hidden;");
        }
    });
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 170 ? "sticky" : "";
    setSticky(stickyClass);
  };

  const headerClass = props.isLight ? `header header-light ${sticky}` : `header ${sticky}`;
  return (
      <header class={headerClass}>
      <div class="container"> 
        <nav class="navbar navbar-expand-lg"><Link class="navbar-brand" to="/"> 
            <div class="header-logo">
              <img src={logo} alt="Logo" />
              <span class="header-logo-text">Technodemy</span>
            </div></Link>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0 rounded">
              <li class="nav-item mt-lg-0 mt-2 mx-2">
                <Link class="nav-link p-0 py-lg-0 py-2 d-flex align-items-center" to="/">
                  Home </Link>
              </li>
              <li class="nav-item dropdown mt-lg-0 mt-2 mx-2"> 
                <button class="nav-link p-0 py-lg-0 py-2 dropdown-toggle d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Programs <span class="dropdown-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24">
                      <path class="header-svg-fill" d="M12,15.5a1,1,0,0,1-.71-.29l-4-4A1,1,0,0,1,8.71,9.79L12,13.1l3.3-3.18a1,1,0,1,1,1.38,1.44l-4,3.86A1,1,0,0,1,12,15.5Z"></path>
                    </svg></span></button>
                <ul class="dropdown-menu border-0">
                  <li><Link class="dropdown-item" to="/programs">All Certificate Programs</Link></li>
                  { programs?.map((program) => (
                    <li><Link class="dropdown-item" to={"/program/"+program?.tag}>{ program?.name }</Link></li>
                  )) }
                </ul>
              </li>
              <li class="nav-item dropdown mt-lg-0 mt-2 mx-2"> 
                <button class="nav-link p-0 py-lg-0 py-2 dropdown-toggle d-flex align-items-center" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Exam Preparations <span class="dropdown-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewbox="0 0 24 24">
                      <path class="header-svg-fill" d="M12,15.5a1,1,0,0,1-.71-.29l-4-4A1,1,0,0,1,8.71,9.79L12,13.1l3.3-3.18a1,1,0,1,1,1.38,1.44l-4,3.86A1,1,0,0,1,12,15.5Z"></path>
                    </svg></span></button>
                <ul class="dropdown-menu border-0">
                  <li><Link class="dropdown-item" to="/exampreps">All Exam Preps</Link></li>
                  { exampreps?.map((program) => (
                    <li><Link class="dropdown-item" to={"/examprep/"+program?.tag}>{ program?.name }</Link></li>
                  )) }
                </ul>
              </li>
              <li class="nav-item mt-lg-0 mt-2 mx-2">
                <Link class="nav-link p-0 py-lg-0 py-2 d-flex align-items-center" to="contact-us">
                  Contact Us </Link>
              </li>
            </ul>
            <a class="d-block d-sm-none mt-4 mx-2 btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Register</a>
          </div>
          <div class="d-flex align-items-center">
            <button class="navbar-toggler ms-2 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"></button>
            <a class="btn btn-primary d-none d-sm-block ms-4" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Register</a>
          </div>
        </nav>
      </div>
    </header>
  );
}
  
 export default Header;
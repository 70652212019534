import { useEffect } from 'react';

function NotFoundPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }, []);
      
    return (
        <main class="main-page-content main-top-gap">
            <section class="courses-banner py-8 bg-secondary position-relative">
                <div class="container"> 
                    <div class="row align-items-center"> 
                        <div class="col-12">
                            <h1 class="banner-heading m-0">Page Not Found</h1>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    );
  }
  
  export default NotFoundPage;
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

import programs from '../../../data/programs.json';

import timeIcon from '../../../assets/images/time-icon.svg';
import crdPlay from '../../../assets/images/crd-play.svg';
import checkPoint from "../../../assets/images/check-point.svg";
import learnStepWebp from "../../../assets/images/learn-step.webp";
import learnStepPng from "../../../assets/images/learn-step.png";

function ProgramsPage() {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
      }, []);

    return (
        <main class="main-page-content main-top-gap">
            <section class="courses-banner py-8 bg-secondary position-relative">
                <div class="container"> 
                    <div class="row align-items-center"> 
                        <div class="col-12">
                            <h1 class="banner-heading m-0">All Certificate Programs</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section class="py-8 courses-search">
                <div class="container"> 
                <div class="sec-content"> 
                    <div class="row multi-course-crd-list"> 
                        { programs?.map((program) => (
                            <div class="col-md-6 col-lg-4">
                                <div class="course-card">
                                    <div class="crd-img-wrap position-relative"> 
                                    <Link to={"/program/"+program?.tag}>
                                        <picture> 
                                        <source srcset={"/images/programs/"+program?.listingImage+".webp"} type="image/webp"/><img class="crd-img" src={"/images/programs/"+program?.listingImage+".jpg"}  alt="Program"/>
                                        </picture></Link>
                                    </div>
                                    <div class="crd-content">
                                    <div class="crd-info py-2 d-flex align-items-center justify-content-between">
                                        <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={timeIcon} alt="Duration"/><span class="crd-info-txt">{ program?.duration } weeks</span></div>
                                        <div class="crd-info-item d-flex align-items-center"><img class="me-1" src={crdPlay} alt="Lessons"/><span class="crd-info-txt">{ program?.modules?.reduce((acc, val) => acc + val?.lessons.length, 0) } lessons</span></div>
                                    </div><Link to={"/program/"+program?.tag}>
                                        <h3 class="card-title my-3">{ program?.name }</h3></Link>
                                    </div>
                                </div>
                            </div>
                        )) }
                    </div>
                </div>
                </div>
            </section>
            <section class="learn-steps-sec-v3 pb-8"> 
                <div class="container">
                <div class="row align-items-center learn-steps-v3-content position-relative py-5">
                    <div class="col-md-6 mt-4 mt-md-0 order-5 order-md-0 px-4">
                    <h2 class="sec-heading">Get more close with your courses.</h2>
                    <p class="sec-brief mt-3 mb-4">We provide a wide range of courses for the different sectors you can choose from, which suits you perfectly</p>
                    <ul class="know-about-points mb-5"> 
                        <li class="know-about-point d-flex align-items-center mb-3"><img class="me-3" src={checkPoint} alt="Check point"/>
                        <h6 class="card-text text-medium-bold">Detail description of each course</h6>
                        </li>
                        <li class="know-about-point d-flex align-items-center mb-3"><img class="me-3" src={checkPoint} alt="Check point"/>
                        <h6 class="card-text text-medium-bold">Easy to understand language</h6>
                        </li>
                        <li class="know-about-point d-flex align-items-center"><img class="me-3" src={checkPoint} alt="Check point"/>
                        <h6 class="card-text text-medium-bold">Simple and easy to learn courses.</h6>
                        </li>
                    </ul><a class="btn btn-primary" href="https://forms.gle/cvjhWSsyD8XgFQZe9" target="_blank" rel="noreferrer">Start learning now</a>
                    </div>
                    <div class="col-md-6 px-4">
                    <picture> 
                        <source srcset={learnStepWebp} type="image/webp"/><img src={learnStepPng} alt="Learn steps"/>
                    </picture>
                    </div>
                </div>
                </div>
            </section>
            </main>
    );
  }
  
  export default ProgramsPage;